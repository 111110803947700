@font-face {
  font-family: "sh new";
  src: font-url("../fonts/sh-new-regular.eot") format("embedded-opentype");
  src: font-url("../fonts/sh-new-regular.otf") format("truetype");
  src: font-url("../fonts/sh-new-regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "sh new";
  src: font-url("../fonts/sh-new-bold.eot") format("embedded-opentype");
  src: font-url("../fonts/sh-new-bold.otf") format("truetype");
  src: font-url("../fonts/sh-new-bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "sh new";
  src: font-url("../fonts/sh-new-medium.eot") format("embedded-opentype");
  src: font-url("../fonts/sh-new-medium.otf") format("truetype");
  src: font-url("../fonts/sh-new-medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

.sh-new {
  font-family: "sh new";
}
