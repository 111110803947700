$actionColor: #00353d;
$border-color: #dfdfdf;
$error-color: #700;
$near-white-transparent: rgba(244, 244, 244, 0.8);

* {
  min-height: 0;
  min-width: 0;
  box-sizing: border-box;
}

a,
a:link,
a:visited,
a:focus,
a:hover,
a:active {
  color: $actionColor;
  text-decoration: none;
}

html,
body {
  height: 100%;
}

input,
select,
textarea {
  border: 1px solid $border-color;
}

.shadow-6 {
  box-shadow: 0 11px 26px 0 rgba(0, 0, 0, 0.03);
}

body {
  font-family: "Inter";
}

.pt100 {
  padding-top: 100%;
}

.bg-action {
  background-color: $actionColor;
}

.bg-near-white-transparent {
  background-color: $near-white-transparent;
}

.b--action {
  border-color: $actionColor;
}

.action {
  color: $actionColor;
}

.translate-y-05 {
  transform: translateY(0.5rem);
}

.pointer {
  cursor: pointer;
}

.line {
  box-sizing: border-box;
  height: 3px;
  width: 37px;
  border: 1.5px solid #d7d7d7;
}

.arrow-up {
  box-sizing: border-box;
  height: 6px;
  width: 6px;
  border-width: 1.5px 1.5px 0 0;
  border-style: solid;
  transform: rotate(315deg);
}

.arrow-down {
  box-sizing: border-box;
  height: 6px;
  width: 6px;
  border-width: 0 0 1.5px 1.5px;
  border-style: solid;
  transform: rotate(315deg);
}

.slick-prev,
.slick-next {
  background: gray !important;
}

.gray-out {
  opacity: 0.5;
}

.grow {
  backface-visibility: visible;
}

.tooltip {
  position: relative;
}

.hidden {
  visibility: hidden;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 160px;
  background-color: white;
  border: 1px solid $actionColor;
  text-align: center;
  padding: 10px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  bottom: 25px;
  right: 0;
}

.tooltip .tooltiptext-btn {
  right: 120px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

@media screen and (min-width: 1024px) {
  .tooltip .tooltiptext {
    right: -70px;
  }

  .tooltip .tooltiptext::after {
    content: " ";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: $actionColor transparent transparent transparent;
  }
}
.tooltip-selectPage .tooltiptext {
  right: -70px;
}
.tooltip-selectPage .tooltiptext::after {
  content: " ";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: $actionColor transparent transparent transparent;
}

.hover-shadow-blue:hover {
  box-shadow: 0 11px 26px 0 rgba(150, 204, 255, 0.38);
}

.bg-top-right {
  background-position: top right;
}

.justify-evenly {
  justify-content: space-evenly;
}

.top-40 {
  top: 40%;
}

.top-50 {
  top: 50%;
}

.left-3 {
  left: 4rem;
}

.left--3 {
  left: -4rem;
}

.right-3 {
  right: 4rem;
}

.right--3 {
  right: -4rem;
}

@media screen and (min-width: 30em) {
  .top-50-ns {
    top: 50%;
  }

  .left-3-ns {
    left: 4rem;
  }

  .left--3-ns {
    left: -4rem;
  }

  .right-3-ns {
    right: 4rem;
  }

  .right--3-ns {
    right: -4rem;
  }

  .w4-5-ns {
    width: 12rem;
  }
}

.w4-5 {
  width: 12rem;
}

.select-blue {
  color: $actionColor;
}

.select-bg {
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIxMXB4IiBoZWlnaHQ9IjdweCIgdmlld0JveD0iMCAwIDExIDciIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+ICAgICAgICA8dGl0bGU+QXJyb3c8L3RpdGxlPiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4gICAgPGRlZnM+PC9kZWZzPiAgICA8ZyBpZD0iU3VtbWFyeS0vLWxpc3QtaXRlbSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTU3NS4wMDAwMDAsIC0yOS4wMDAwMDApIiBzdHJva2UtbGluZWNhcD0ic3F1YXJlIj4gICAgICAgIDxnIGlkPSJTdGFja2VkLUdyb3VwIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwLjAwMDAwMCwgMC43MDMxMjUpIiBzdHJva2U9IiM4NDg0ODQiIHN0cm9rZS13aWR0aD0iMS41Ij4gICAgICAgICAgICA8ZyBpZD0iSXRlbS0xIj4gICAgICAgICAgICAgICAgPHBvbHlsaW5lIGlkPSJBcnJvdyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNTgwLjQ4ODI4MSwgMzAuMDAwMDAwKSByb3RhdGUoLTMxNS4wMDAwMDApIHRyYW5zbGF0ZSgtNTgwLjQ4ODI4MSwgLTMwLjAwMDAwMCkgIiBwb2ludHM9IjU3Ny42MjgwNjUgMzIuOTk1OTU3OCA1ODMuMzQ4NDk4IDMyLjk5NTk1NzggNTgzLjM0ODQ5OCAyNy4wMDQwNDIyIj48L3BvbHlsaW5lPiAgICAgICAgICAgIDwvZz4gICAgICAgIDwvZz4gICAgPC9nPjwvc3ZnPg==)
    no-repeat 95% 50%;
}

select::-ms-expand {
  display: none;
}

.error {
  color: $error-color;
  font-weight: 500;
}

@media screen and (max-width: 1024px) {
  .lg-flex-col {
    flex-direction: column;
  }

  .lg-flex-gap {
    gap: 16px;
  }

  .lg-mb {
    margin-bottom: 32px;
  }

  .lg-mblock {
    margin-block: 32px;
  }

  .lg-p-inline {
    padding-inline: 32px;
  }
}
