.button {
  @apply font-thin font-sans cursor-pointer px-8 py-3;

  &__primary {
    @apply text-white;
    background-color: #00353D;

  }

  &__secondary {
    @apply text-black;
    background-color: #F5F6F6;
  }

}
